<template>
  <b-row>
    <b-col cols="12">
      <b-table
        small
        :items="rows"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        responsive
        striped
        foot-clone
      >
        <template #cell(amount)="data">
          {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) }}
        </template>

        <template #foot(title)="data">
          <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
        </template>

        <template #foot(amount)="data">
          <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalClicks) }}</strong>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'

export default {
  components: {
    BRow,
    BCol,
    BTable,
  },
  props: {
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    rows: {
      type: Array,
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      sortBy: 'amount',
      sortDesc: true,
      sortDirection: 'asc',
    }
  },
  computed: {
    totalClicks() {
      let totalCicks = 0
      this.rows.forEach(i => {
        totalCicks += i.amount
      })
      return totalCicks
    },
  },
}
</script>
<style>
.payment-cell {
  width: 10%;
}
</style>
