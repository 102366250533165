<template>
  <b-row>
    <b-col cols="12">
      <b-table
        small
        :items="rows"
        :fields="fields"
        responsive
        striped
        foot-clone
      >
        <template #cell(balance)="data">
          {{ currency(data.item.currency, data.value) }}
        </template>

        <template #cell(amount)="data">
          {{ currency(data.item.currency, data.value) }}
        </template>

        <template #foot(title)="data">
          <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
        </template>

        <template #foot(owner)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(email)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(balance)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(amount)="data">
          <strong :data-label="data.label">{{ currency('USD', totalAmount) }}</strong>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'

export default {
  components: {
    BRow,
    BCol,
    BTable,
  },
  props: {
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    rows: {
      type: Array,
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      currency: currencyFormatter,
    }
  },
  computed: {
    fieldsLocale() {
      return this.fields.map(f => ({
        key: f.label,
        label: this.$t(`page.features.${f.value}`),
      }))
    },
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
  },
}
</script>
<style>
.payment-cell {
  width: 10%;
}
</style>
