<template>
  <b-row>
    <b-col cols="12">
      <b-table
        small
        striped
        hover
        :items="calculatedItems"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :tbody-tr-class="rowClass"
        foot-clone
        responsive
        no-footer-sorting
        @sort-changed="onSortChanged"
      >
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <template #cell(domain)="data">
          {{ data.value }}
        </template>

        <template #cell(balance)="data">
          {{ currency(data.item.currency, data.value) }}
        </template>

        <template #cell(amount)="data">
          {{ currency(data.item.currency, data.value) }}
        </template>
        <template #cell(ecpm)="data">
          {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.ecpm) }}
        </template>

        <template #cell(show)="data">
          {{ data.value.toLocaleString('ru-RU') }}
        </template>

        <template #foot(title)="data">
          <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          <span v-if="paramsFromUrl.from && paramsFromUrl.to"> ({{ new Date(paramsFromUrl.from).toLocaleDateString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric" }) }} - {{ new Date(paramsFromUrl.to).toLocaleDateString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric" }) }})</span>
        </template>

        <template #foot(owner)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(email)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(balance)="data">
          <span :data-label="data.label" />
        </template>

        <template
          v-if="totals.length !== 0"
          #foot(amount)="data"
        >
          <div
            v-for="key in Object.keys(totals)"
            :key="key"
          >
            <strong :data-label="data.label">{{ countCurencyTotal(key, 'amount') }}</strong>
          </div>
        </template>

        <template #foot(show)="data">
          <strong :data-label="data.label">{{ totalAmount('show') }}</strong>
        </template>

        <template #foot(ecpm)="data">
          <span :data-label="data.label" />
        </template>

        <template #foot(payment_method)="data">
          <span :data-label="data.label" />
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'

export default {
  components: {
    BRow,
    BCol,
    BTable,
  },
  props: {
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    rows: {
      type: Array,
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      sortBy: 'amount',
      sortDesc: true,
      sortDirection: 'asc',
      totals: [],
      paramsFromUrl: {},
    }
  },
  computed: {
    calculatedItems() {
      return this.rows.map(item => ({
        ...item,
        ecpm: (item.amount / item.show) * 1000,
      }))
    },
  },
  created() {
    this.addTotals()
    this.getParamsFromUrl()
  },
  methods: {
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy
      this.sortDesc = sortDesc
    },
    rowClass() {
      /*
      item - argument
      const { amount, show } = item
      if (show !== 0) {
        const ecpm = (amount / show) * 1000
        if (ecpm < 0.009) {
          return 'table-danger'
        }

        if (ecpm < 0.02 && ecpm > 0.009) {
          return 'table-warning'
        }
      } else {
        return 'table-danger'
      }
      */
      return ''
    },
    getParamsFromUrl() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      this.paramsFromUrl = Object.fromEntries(urlSearchParams.entries())
    },
    addTotals() {
      this.totals = this.reGroup(this.rows, 'currency')
    },
    reGroup(data, property) {
      return data.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
    countCurencyTotal(currencyKey, value) {
      const initialValue = 0
      if (Object.keys(this.totals[currencyKey]).length) {
        const sum = Object.values(this.totals[currencyKey]).reduce((accumulator, currentValue) => accumulator + currentValue[value], initialValue)
        return this.currency(currencyKey, sum)
      }
      return 0
    },
    totalAmount(key) {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i[key]
      })
      return totalAmount.toLocaleString('ru-RU')
    },
  },
}
</script>
<style>
.payment-cell {
  width: 15%;
}
</style>
